<template>
  <div style="margin-top: 2rem">
    <div class="table-container">
      <div class="add-container">
        <el-input
          placeholder="Type to search"
          v-model="searchKey"
          style="width: 30%"></el-input>
        <el-button type="primary" @click="dialogVisible = true"
          >Add Account</el-button
        >
      </div>

      <el-table :data="chartAccounts" height="50vh" v-loading="tableLoading">
        <el-table-column label="Account ID" prop="account_id"></el-table-column>
        <el-table-column label="Name" prop="name"></el-table-column>
        <el-table-column
          label="Description"
          prop="description"></el-table-column>
        <el-table-column
          label="Account Type"
          prop="account_type"></el-table-column>
        <el-table-column label="Currency" prop="currency"></el-table-column>
        <el-table-column label="Charge" prop="charge"> </el-table-column>
        <el-table-column label="Status" prop="">
          <template slot-scope="scope">
            <p
              v-if="scope.row.status == 0"
              style="color: #ee6463; margin: 0 auto">
              Inactive
            </p>
            <p
              v-if="scope.row.status == 1"
              style="color: #56db71; margin: 0 auto">
              Active
            </p>
          </template>
        </el-table-column>
        <el-table-column label="Action" prop="">
          <template slot-scope="scope">
            <div>
              <button
                title="Update Chart Account"
                class="edit-button"
                @click="openEditDialog({ row: scope.row })">
                <editIcon class="is-editing"></editIcon>
              </button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="col-md-12" style="text-align: center; margin-top: 1em">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          :total="total">
        </el-pagination>
      </div>
    </div>

    <div class="modal-container">
      <el-dialog title="Add Account" :visible.sync="dialogVisible" width="30%">
        <el-form
          :model="formData"
          :rules="rules"
          ref="ruleForm"
          @submit.prevent.native="checkEnter"
          label-position="left">
          <el-form-item label="Name" prop="name">
            <el-input
              placeholder="Account ID"
              v-model="formData.name"></el-input>
          </el-form-item>
          <el-form-item label="Description" prop="description">
            <el-input
              placeholder="Description"
              v-model="formData.description"></el-input>
          </el-form-item>
          <el-form-item
            label="Currency"
            prop="currency"
            style="display: flex; flex-direction: column">
            <el-select
              v-model="formData.currency"
              filterable
              style="width: 100%">
              <el-option label="GHS" :value="'GHS'"></el-option>
              <el-option label="USD" :value="'USD'"></el-option>
              <el-option label="EUR" :value="'EUR'"></el-option>
              <el-option label="CFA" :value="'CFA'"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Charge" prop="charge">
            <el-input placeholder="Charge" v-model="formData.charge"></el-input>
          </el-form-item>
          <el-button
            type="primary"
            style="width: 100%; margin: 0 auto"
            @click="checkEnter"
            >Submit</el-button
          >
        </el-form>
      </el-dialog>
      <!-- Confirmation -->
      <el-dialog title="Confirm" :visible.sync="confirmModal">
        <div class="heading">
          <p>Are you sure you want to add this account?</p>
        </div>
        <div class="preview">
          <p style="font-size: 11px">
            Account Name: &nbsp;
            <strong style="font-size: 14px">{{ formData.name }}</strong>
          </p>
          <p style="font-size: 11px">
            Description: &nbsp;
            <strong style="font-size: 14px">{{ formData.description }}</strong>
          </p>
          <p style="font-size: 11px">
            Currency: &nbsp;
            <strong style="font-size: 14px">{{ formData.currency }}</strong>
          </p>
          <p style="font-size: 11px">
            Charge: &nbsp;
            <strong style="font-size: 14px">{{ formData.charge }}</strong>
          </p>
        </div>
        <div class="submit-container">
          <el-button type="primary" @click="confirmModal = false"
            >Cancel</el-button
          >
          <el-button
            type="primary"
            @click="submitNewData"
            :loading="buttonLoading"
            >Confirm</el-button
          >
        </div>
      </el-dialog>
      <!-- Edit -->
      <el-dialog
        title="Edit Account"
        :visible.sync="editDialogVisible"
        width="30%"
        v-if="editDialogVisible"
        top="1vh">
        <el-form :model="editFormData" ref="EditForm" label-position="left">
          <el-form-item label="Name" prop="nameEdit">
            <el-input
              placeholder="Account ID"
              v-model="editFormData.name"></el-input>
          </el-form-item>
          <el-form-item label="Description" prop="descriptionEdit">
            <el-input
              placeholder="Description"
              v-model="editFormData.description"></el-input>
          </el-form-item>
          <el-form-item
            label="Currency"
            prop="currencyEdit"
            style="display: flex; flex-direction: column">
            <el-select
              v-model="editFormData.currency"
              filterable
              style="width: 100%">
              <el-option label="GHS" :value="'GHS'"></el-option>
              <el-option label="USD" :value="'USD'"></el-option>
              <el-option label="EUR" :value="'EUR'"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Charge Amount" prop="charge">
            <el-input
              placeholder="Charge"
              v-model="editFormData.charge"></el-input>
          </el-form-item>
          <el-form-item
            label="Status"
            prop="status"
            style="display: flex; flex-direction: column">
            <el-radio-group v-model="editFormData.status">
              <el-radio :label="1" :value="1">Enable</el-radio>
              <el-radio :label="0" :value="0">Disable</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-button
            type="primary"
            style="width: 100%; margin: 0 auto"
            @click="updateDetails"
            :loading="buttonLoading"
            >Submit</el-button
          >
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
  import store from "@/state/store.js";
  import editIcon from "./editIcon.vue";
  export default {
    data() {
      return {
        dialogVisible: false,
        confirmModal: false,
        editDialogVisible: false,
        page: 1,
        pageSize: 20,
        total: 0,
        buttonLoading: false,
        tableLoading: false,
        searchKey: "",
        formData: {
          name: "",
          description: "",
          currency: "",
          charge: "",
        },
        editFormData: {
          name: "",
          description: "",
          currency: "",
          charge: "",
          status: "",
        },
        rules: {
          name: [
            {
              required: true,
              message: "Account name is required.",
              trigger: "blur",
            },
          ],
          description: [
            {
              required: true,
              message: "Description is required",
              trigger: "blur",
            },
          ],
          currency: [
            { required: true, message: "Select a currency", trigger: "blur" },
          ],
          charge: [
            { required: true, message: "Charge is required", trigger: "blur" },
          ],
        },
      };
    },
    components: {
      editIcon,
    },
    mounted() {
      this.tableLoading = true;
      store.dispatch("admin/reporting/getChartAccounts").then((data) => {
        this.tableLoading = false;
      });
    },
    methods: {
      checkEnter(e) {
        e.preventDefault();
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.confirmModal = true;
          } else {
            return false;
          }
        });
      },
      submitNewData() {
        this.buttonLoading = true;
        store
          .dispatch("admin/reporting/addChartAccount", this.formData)
          .then((data) => {
            this.buttonLoading = false;
            this.confirmModal = false;
            this.dialogVisible = false;
            this.$message({
              type: "success",
              message: "Account added successfully!",
            });
            this.$refs.ruleForm.resetFields();
            this.refetchData();
          })
          .catch((err) => {
            this.buttonLoading = false;
            this.confirmModal = false;
            this.dialogVisible = false;
            this.$message({
              type: "error",
              message: "Error adding account!",
            });
            this.$refs.ruleForm.resetFields();
          });
      },
      handleCurrentChange(val) {
        this.page = val;
      },
      refetchData() {
        this.tableLoading = true;
        store.dispatch("admin/reporting/getChartAccounts").then((data) => {
          this.tableLoading = false;
        });
      },
      openEditDialog(row) {
        this.editFormData = {
          account_id: row.row.account_id,
          name: row.row.name,
          description: row.row.description,
          currency: row.row.currency,
          status: row.row.status,
          charge: row.row.charge,
        };
        this.editDialogVisible = true;
      },
      updateDetails(e) {
        e.preventDefault();
        // validate form
        if (
          this.editFormData.name == "" ||
          this.editFormData.description == "" ||
          this.editFormData.currency == "" ||
          this.editFormData.charge == "" ||
          this.editFormData.status == ""
        ) {
          this.$message({
            type: "error",
            message: "Please fill all fields!",
          });
          return;
        } else {
          this.buttonLoading = true;
          const payload = {
            account_id: this.editFormData.account_id,
            name: this.editFormData.name,
            description: this.editFormData.description,
            currency: this.editFormData.currency,
            status: this.editFormData.status,
            charge: this.editFormData.charge,
          };
          store
            .dispatch("admin/reporting/updateChartAccount", payload)
            .then((data) => {
              this.buttonLoading = false;
              this.editDialogVisible = false;
              this.$message({
                type: "success",
                message: "Account updated successfully!",
              });
              this.refetchData();
            })
            .catch((err) => {
              this.buttonLoading = false;
              this.editDialogVisible = false;
              this.$message({
                type: "error",
                message: "Error updating account!",
              });
            });
        }
      },
    },
    computed: {
      chartAccounts() {
        return store.getters["admin/reporting/chartAccounts"].filter((item) => {
          return (
            item.name.toLowerCase().includes(this.searchKey.toLowerCase()) ||
            item.description
              .toLowerCase()
              .includes(this.searchKey.toLowerCase()) ||
            item.currency
              .toLowerCase()
              .includes(this.searchKey.toLowerCase()) ||
            item.currency.toLowerCase().includes(this.searchKey.toLowerCase())
          );
        });
      },
    },
  };
</script>
<style scoped>
  .add-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;
  }

  .edit-button {
    background-color: transparent;
    border: none;
  }
</style>
